import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import Pdf from "../../assets/images/pdf.png";
import TrashIcon from "../../assets/svg/TrashIcon";
import RightUpArrow from "../../assets/svg/RightUpArrow";
import DownloadIcon from "../../assets/svg/DownloadIcon";
import jsPDF from "jspdf";
import { LanguageContext } from "../../context/LanguageContext";
import { FormattedMessage } from "react-intl";

const EmployeeAttachment = ({
  title,
  selectedFile,
  setSelectedFile,
  files,
  handleEmployeeAttachmentDelete,
  selectedPage,
}) => {
  // console.log('selectedPage: ', selectedPage);
  // console.log("files: ", files);
  const { locale, setLocale } = useContext(LanguageContext);

  const handleDownload = (fileUrl) => {
    const newWindow = window.open(fileUrl, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      // console.error("Failed to open new tab/window.");
    }
    // const link = document.createElement("a");
    // link.href = fileUrl;
    // link.download = fileUrl.split("/").pop();
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  // const handleDelete = (file) => {
  //   setSelectedFile((prevFiles) => prevFiles.filter((f) => f !== file));
  // };

  return (
    <>
      {selectedPage == "headphone" ? (
        <div>
          {files?.data?.empfiles?.length > 0 && (
            <p
              className="employeeAttachment"
              style={{ direction: locale === "en" ? "ltr" : "rtl" }}
            >
              {title}
            </p>
          )}
          {files?.data?.empfiles?.map((file, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                height: "30%",
              }}
              className="cardBgColor"
            >
              <div className="pdfImgLogo" style={{ paddingTop: "12px" }}>
                <img
                  src={Pdf}
                  alt="PDF icon"
                  className="imgPlant"
                  style={{ width: "60px", height: "80px" }}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="store"
              >
                <p
                  className="plantName"
                  style={{
                    textOverflow: "ellipsis",
                    width: "230px",
                    overflow: "hidden",
                  }}
                >
                  {/* {"Employee Attachment"} */}
                  <FormattedMessage id="Employee_Attachment" />
                </p>
                <div className="EmpAttachIcon">
                  <div
                    onClick={() => handleDownload(file?.file)}
                    style={{ cursor: "pointer" }}
                  >
                    <DownloadIcon width={40} height={40} />
                  </div>
                  {files?.status !== "APPROVED" &&
                    files?.status !== "REJECTED" && (
                      <div
                        onClick={() => handleEmployeeAttachmentDelete(file?.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <TrashIcon width={40} height={40} />
                      </div>
                    )}
                </div>
              </div>
              <div
                style={{
                  color: "#535353",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                {/* <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p> */}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {files?.fullData?.employee_attachments?.length > 0 && (
            <p
              className="employeeAttachment"
              style={{ direction: locale === "en" ? "ltr" : "rtl" }}
            >
              {title}
            </p>
          )}
          {files?.fullData?.employee_attachments?.map((file, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "10px",
                height: "30%",
              }}
              className="cardBgColor"
            >
              <div className="pdfImgLogo" style={{ paddingTop: "12px" }}>
                <img
                  src={Pdf}
                  alt="PDF icon"
                  className="imgPlant"
                  style={{ width: "60px", height: "80px" }}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="store"
              >
                <p
                  className="plantName"
                  style={{
                    textOverflow: "ellipsis",
                    width: "230px",
                    overflow: "hidden",
                  }}
                >
                  {/* {"Employee Attachment"} */}
                  <FormattedMessage id="Employee_Attachment" />
                </p>
                <div className="EmpAttachIcon">
                  <div
                    onClick={() => handleDownload(file?.file)}
                    style={{ cursor: "pointer" }}
                  >
                    <DownloadIcon width={40} height={40} />
                  </div>
                  {files?.status !== "APPROVED" &&
                    files?.status !== "REJECTED" && (
                      <div
                        onClick={() => handleEmployeeAttachmentDelete(file?.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <TrashIcon width={40} height={40} />
                      </div>
                    )}
                </div>
              </div>
              <div
                style={{
                  color: "#535353",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                {/* <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
// const EmployeeAttachment = ({ title, selectedFile }) => {
//   // console.log(
//   //   "🚀 ~ EmployeeAttachment ~ requestData:",
//   //   requestData?.fullData?.request
//   // );
//   // const generatePDF = (data) => {};

//   // const handleDownload = () => {
//   //   // const pdf = new jsPDF();
//   //   // pdf.text("Reasons of Return!", 10, 10);
//   //   // pdf.save("reason_of_return.pdf");
//   //   const doc = new jsPDF();
//   //   let yPos = 20;

//   //   doc.setFontSize(12);
//   //   doc.text("Service Request Details", 105, yPos, { align: "center" });
//   //   yPos += 10;

//   //   requestData?.fullData?.request.forEach((item) => {
//   //     doc.setFont("helvetica", "bold");
//   //     doc.text(item.feild_type + ":", 20, yPos);
//   //     doc.setFont("helvetica");
//   //     doc.text(item.value.toString(), 40, yPos);
//   //     yPos += 10;
//   //   });

//   //   // console.log("🚀 ~outside", data)
//   //   // if (data) {
//   //   // console.log("🚀 ~inside", data)
//   //   // const doc = generatePDF(data);
//   //   doc.save("service_request_details.pdf");
//   //   // }
//   // };
//   const generatePDF = () => {
//     const doc = new jsPDF();
//     selectedFile.forEach((uploadedFile, index) => {
//       if (uploadedFile.file.type.startsWith("image/")) {
//         doc.addImage(uploadedFile.base64, "JPEG", 10, index * 80 + 10, 90, 80);
//       } else if (uploadedFile.file.type === "application/pdf") {
//         // Handle PDF files if needed
//       }
//     });
//     doc.save("uploaded_files.pdf");
//   };

//   return (
//     <>
//       <div>
//         <p className="employeeAttachment">{title}</p>
//         <div style={{ display: "flex", gap: "10px" }} className="cardBgColor">
//           <div className="pdfImgLogo">
//             <img src={Pdf} alt="img tumer" className="imgPlant" />
//           </div>
//           <div
//             style={{ display: "flex", flexDirection: "column" }}
//             className="store"
//           >
//             <p className="plantName">Reason of return</p>
//             {/* <div className="storeDiv">
//               <p
//                 style={{
//                   color: "#535353",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   fontFamily: "Alexandria",
//                   marginBottom: "0px",
//                 }}
//               >
//                 2023-01-01 | 12:00 pm
//               </p>
//             </div> */}
//             <div className="EmpAttachIcon">
//               {/* <RightUpArrow width={50} height={50} /> */}
//               <div onClick={generatePDF}>
//                 <DownloadIcon width={50} height={50} />
//               </div>
//               <TrashIcon width={50} height={50} />
//             </div>
//           </div>
//           <div
//             style={{
//               color: "#535353",
//               display: "flex",
//               alignItems: "center",
//               marginLeft: "20px",
//             }}
//           >
//             {/* <p>357 MB</p> */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
export default EmployeeAttachment;
