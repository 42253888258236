import { useState } from "react";

const Button = ({
  title,
  onClick,
  className,
  hoverClassName,
  disable,
  style,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonClass = isHovered ? hoverClassName : className || "btn-class";
  return (
    <div>
      {/* <button className={buttonClass}  */}
      <button
        className={className ? className : "btn-class"}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={disable || false}
        style={style}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
