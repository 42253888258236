import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Employee from "./pages/Employee";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./ProtectedRoutes";
import { IntlProvider } from "react-intl";
import { LanguageContext } from "./context/LanguageContext";
import enMessages from "../src/languages/en.json";
import arMessages from "../src/languages/ar.json";
import { useEffect, useState } from "react";

function App() {
  const hasToken = sessionStorage.getItem("token");
  const [locale, setLocale] = useState();
  const messages = {
    en: enMessages,
    ar: arMessages,
  };
  useEffect(() => {
    const language = sessionStorage.getItem("lang");
    setLocale(language || "en");
  }, []);

  return (
    <div className="App">
      <IntlProvider locale={locale} messages={messages[locale]}>
        <LanguageContext.Provider value={{ locale, setLocale }}>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/" element={<Employee />} />
              <Route path="/employee" element={<Employee />}></Route>
            </Route>
          </Routes>
        </LanguageContext.Provider>
      </IntlProvider>
    </div>
  );
}

export default App;
