import { Col } from "react-bootstrap";
import UploadIcon from "../../assets/images/UploadIcon.png";
import { FiUploadCloud } from "react-icons/fi";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import jsPDF from "jspdf";
import { FormattedMessage } from "react-intl";

const DragFiles = ({
  isPlantImages,
  isPlantVideos,
  plantImages,
  setPlantImages,
  setPlantVideo,
  setSelectedFile,
  disable
}) => {
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  // const onDrop = useCallback((acceptedFiles) => {
  //   const pdfFiles = acceptedFiles.filter(file => file.type === "application/pdf");
  //   setselectedFile((prevFiles) => [...prevFiles, ...pdfFiles]);
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  // });
  const onDrop = useCallback(
    (acceptedFiles) => {
      const pdfFiles = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      if (pdfFiles.length > 0) {
        setSelectedFile([pdfFiles[0]]);
      }
    },
    [setSelectedFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
    maxFiles: 1,
  });

  // useEffect(() => {
  //   setselectedFile(uploadedFiles);
  // }, [uploadedFiles, setselectedFile]);

  return (
    <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
      <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
        {(isPlantImages || isPlantVideos) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                padding: "10px 0px",
                color: "#292929",
                fontSize: 24,
                fontFamily: "Alexandria",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              {isPlantVideos ? "Plant Videos" : "Plant Images"}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p
                style={{
                  color: "#6E4C3E",
                  fontSize: 20,
                  fontFamily: "Alexandria",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                Details
              </p>
              <p
                style={{
                  color: "#535353",
                  fontSize: 20,
                  fontFamily: "Alexandria",
                  fontWeight: "300",
                  wordWrap: "break-word",
                }}
              >
                Logs
              </p>
            </div>
          </div>
        )}

        <div style={{ width: "100%", display: "flex" }}>
          <ul style={{ listStyle: "none", display: "flex", gap: "10px" }}></ul>
        </div>
        <div
          style={{
            border: "1px #A5A5A5 dotted",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            borderRadius: 8,
            cursor: !disable ? "pointer" : "not-allowed",
          }}
          // {...getRootProps()}
          {...(!disable ? getRootProps() : {})}
        >
          <input {...getInputProps()} disabled={!disable} />
          <FiUploadCloud size={50} color="#3E6E4B" />

          <p
            style={{
              color: "black",
              fontSize: 20,
              fontFamily: "Alexandria",
              fontWeight: "700",
              wordWrap: "break-word",
              marginTop: "20px",
            }}
          >
            <FormattedMessage id="Drag_&_drop_files_or_Browse" />
            {/* Drag & drop files or{" "}
            <span
            style={{
              color: "#3E6E4B",
              fontSize: 20,
              fontFamily: "Alexandria",
              fontWeight: "700",
                wordWrap: "break-word",
              }}
              >
              Browse
              </span> */}
          </p>
          <p
            style={{
              textAlign: "center",
              color: "#535353",
              fontSize: 14,
              fontFamily: "Alexandria",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <FormattedMessage id="Supported_formats_PDF" />
            {/* Supported formats: PDF */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DragFiles;

// import { Col } from "react-bootstrap";
// import UploadIcon from "../../assets/images/UploadIcon.png";
// import { FiUploadCloud } from "react-icons/fi";
// import { useCallback, useEffect, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import jsPDF from "jspdf";

// const DragFiles = ({
//   isPlantImages,
//   isPlantVideos,
//   plantImages,
//   setPlantImages,
//   setPlantVideo,
//   setselectedFile,
// }) => {
//   // console.log(plantImages, "plant imgaes");
//   // const [selectedFiles, setSelectedFiles] = useState([]);
//   // const [uploadedImages, setUploadedImages] = useState(
//   //   plantImages?.lenght > 0 ? plantImages : []
//   // );
//   // const onDrop = useCallback(
//   //   (acceptedFiles) => {
//   //     const arr = [];
//   //     const arr2 = [];
//   //     acceptedFiles?.forEach((file) => {
//   //       if (file) {
//   //         arr.push(file);
//   //         const reader = new FileReader();
//   //         reader.onloadend = () => {
//   //           arr2.push(reader.result);
//   //         };
//   //         reader.readAsDataURL(file);
//   //       }
//   //     });
//   //     if (isPlantImages) {
//   //       setPlantImages(arr);
//   //       setUploadedImages(arr2);
//   //     } else {
//   //       console.log("here is the video file", arr);

//   //       setPlantVideo(arr[0]);
//   //     }
//   //   },
//   //   [selectedFiles]
//   // );
//   // const { getRootProps, getInputProps } = useDropzone({
//   //   onDrop: onDrop,
//   // });

//   // const [uploadedFiles, setUploadedFiles] = useState([]);

//   // const onDrop = useCallback((acceptedFiles) => {
//   //   acceptedFiles.forEach((file) => {
//   //     const reader = new FileReader();
//   //     reader.onload = () => {
//   //       const base64String = reader.result.split(",")[1]; // Get base64 string
//   //       setUploadedFiles((prevFiles) => [
//   //         ...prevFiles,
//   //         { file: file, base64: base64String },
//   //       ]);
//   //     };
//   //     reader.readAsDataURL(file); // Read the file as a Data URL
//   //   });
//   // }, []);

//   // const { getRootProps, getInputProps } = useDropzone({
//   //   onDrop: (acceptedFiles) => {
//   //     // Filter acceptedFiles to include only PDFs and images
//   //     const filteredFiles = acceptedFiles.filter(
//   //       (file) =>
//   //         file.type.startsWith("image/") || file.type === "application/pdf"
//   //     );
//   //     onDrop(filteredFiles);
//   //   },
//   // });

//   // useEffect(() => {
//   //   setselectedFile(uploadedFiles);
//   // }, [uploadedFiles]);
//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   const onDrop = useCallback((acceptedFiles) => {
//     acceptedFiles.forEach((file) => {
//       if (file.type === "application/pdf") {
//         const reader = new FileReader();
//         reader.onload = () => {
//           const base64String = reader.result.split(",")[1]; // Get base64 string
//           setUploadedFiles((prevFiles) => [
//             ...prevFiles,
//             { file: file, base64: base64String },
//           ]);
//         };
//         reader.readAsDataURL(file); // Read the file as a Data URL
//       }
//     });
//   }, []);

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: (acceptedFiles) => {
//       onDrop(acceptedFiles);
//     },
//   });

//   const downloadPDF = (base64String, fileName) => {
//     const byteNumbers = new Array(base64String.length);
//     for (let i = 0; i < base64String.length; i++) {
//       byteNumbers[i] = base64String.charCodeAt(i);
//     }
//     const byteArray = new Uint8Array(byteNumbers);
//     const blob = new Blob([byteArray], { type: "application/pdf" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = fileName;
//     a.click();
//   };

//   useEffect(() => {
//     uploadedFiles.forEach((uploadedFile) => {
//       if (uploadedFile.file.type === "application/pdf") {
//         downloadPDF(uploadedFile.base64, uploadedFile.file.name);
//       }
//     });
//   }, [uploadedFiles]);

//   return (
//     <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
//       {/* <button
//               onClick={() => downloadPDF(uploadedFiles.base64, uploadedFiles.file.name)}
//             >
//               Download
//             </button> */}
//       <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
//         {(isPlantImages || isPlantVideos) && (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <div
//               style={{
//                 padding: "10px 0px",
//                 color: "#292929",
//                 fontSize: 24,
//                 fontFamily: "Alexandria",
//                 fontWeight: "700",
//                 wordWrap: "break-word",
//               }}
//             >
//               {isPlantVideos ? "Plant Videos" : "Plant Images"}
//             </div>
//             <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//               <p
//                 style={{
//                   color: "#6E4C3E",
//                   fontSize: 20,
//                   fontFamily: "Alexandria",
//                   fontWeight: "700",
//                   wordWrap: "break-word",
//                 }}
//               >
//                 Details
//               </p>
//               <p
//                 style={{
//                   color: "#535353",
//                   fontSize: 20,
//                   fontFamily: "Alexandria",
//                   fontWeight: "300",
//                   wordWrap: "break-word",
//                 }}
//               >
//                 Logs
//               </p>
//             </div>
//           </div>
//         )}

//         <div style={{ width: "100%", display: "flex" }}>
//           <ul style={{ listStyle: "none", display: "flex", gap: "10px" }}>
//             {/* {uploadedFiles.map((uploadedFile, index) => (
//               <li key={index}>
//                 {uploadedFile.file.type.startsWith("image/") ? (
//                   <img
//                     src={uploadedFile.base64}
//                     alt={`Uploaded Image ${index}`}
//                   />
//                 ) : (
//                   <video controls src={uploadedFile.base64} />
//                 )}
//               </li>
//             ))} */}
//           </ul>
//         </div>
//         <div
//           style={{
//             border: "1px #A5A5A5 dotted",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: "16px",
//             borderRadius: 8,
//           }}
//           {...getRootProps()}
//         >
//           <input {...getInputProps()} />
//           <FiUploadCloud size={50} color="#3E6E4B" />

//           <p
//             style={{
//               color: "black",
//               fontSize: 20,
//               fontFamily: "Alexandria",
//               fontWeight: "700",
//               wordWrap: "break-word",
//               marginTop: "20px",
//             }}
//           >
//             Drag & drop files or{" "}
//             <span
//               style={{
//                 color: "#3E6E4B",
//                 fontSize: 20,
//                 fontFamily: "Alexandria",
//                 fontWeight: "700",
//                 wordWrap: "break-word",
//               }}
//             >
//               Browse
//             </span>
//           </p>
//           <p
//             style={{
//               textAlign: "center",
//               color: "#535353",
//               fontSize: 14,
//               fontFamily: "Alexandria",
//               fontWeight: "400",
//               wordWrap: "break-word",
//             }}
//           >
//             Supported formats: JPEG, PNG, PDF
//           </p>
//         </div>
//       </div>
//       {/* {(isPlantImages || isPlantVideos) && (
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <div
//             style={{
//               padding: "10px 0px",
//               color: "#292929",
//               fontSize: 24,
//               fontFamily: "Alexandria",
//               fontWeight: "700",
//               wordWrap: "break-word",
//             }}
//           >
//             {isPlantVideos ? "Plant Videos" : "Plant Images"}
//           </div>
//           <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//             <p
//               style={{
//                 color: "#6E4C3E",
//                 fontSize: 20,
//                 fontFamily: "Alexandria",
//                 fontWeight: "700",
//                 wordWrap: "break-word",
//               }}
//             >
//               Details
//             </p>
//             <p
//               style={{
//                 color: "#535353",
//                 fontSize: 20,
//                 fontFamily: "Alexandria",
//                 fontWeight: "300",
//                 wordWrap: "break-word",
//               }}
//             >
//               Logs
//             </p>
//           </div>
//         </div>
//       )}

//       <div style={{ width: "100%", display: "flex" }}>
//         <ul style={{ listStyle: "none", display: "flex", gap: "10px" }}>
//           {uploadedImages?.map((file) => (
//             <li key={file.name}>
//               <img src={file} />
//             </li>
//           ))}
//         </ul>
//       </div>
//       <div
//         style={{
//           border: "1px #A5A5A5 dotted",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           padding: "16px",
//           borderRadius: 8,
//         }}
//         {...getRootProps()}
//       >
//         <input {...getInputProps()} />
//         <FiUploadCloud size={50} color="#3E6E4B" />

//         <p
//           style={{
//             color: "black",
//             fontSize: 20,
//             fontFamily: "Alexandria",
//             fontWeight: "700",
//             wordWrap: "break-word",
//             marginTop: "20px",
//           }}
//         >
//           Drag & drop files or{" "}
//           <span
//             style={{
//               color: "#3E6E4B",
//               fontSize: 20,
//               fontFamily: "Alexandria",
//               fontWeight: "700",
//               wordWrap: "break-word",
//             }}
//           >
//             Browse
//           </span>
//         </p>
//         <p
//           style={{
//             textAlign: "center",
//             color: "#535353",
//             fontSize: 14,
//             fontFamily: "Alexandria",
//             fontWeight: "400",
//             wordWrap: "break-word",
//           }}
//         >
//           Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
//         </p>
//       </div> */}
//       {/* <div
//         style={{
//           border: "1px #A5A5A5 dotted",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           padding: "16px",
//           borderRadius: 8,
//         }}
//       >
//         <input
//           type="file"
//           id="file-input"
//           data-testid="file-input"
//           style={{ width: "100%", height: "100%" }}
//           {...getInputProps()}
//         />
//         <label htmlFor="file-input"></label>
//         <FiUploadCloud size={50} color="#3E6E4B" />

//         <p
//           style={{
//             color: "black",
//             fontSize: 20,
//             fontFamily: "Alexandria",
//             fontWeight: "700",
//             wordWrap: "break-word",
//             marginTop: "20px",
//           }}
//         >
//           Drag & drop files or{" "}
//           <span
//             style={{
//               color: "#3E6E4B",
//               fontSize: 20,
//               fontFamily: "Alexandria",
//               fontWeight: "700",
//               wordWrap: "break-word",
//             }}
//           >
//             Browse
//           </span>
//         </p>
//         <p
//           style={{
//             textAlign: "center",
//             color: "#535353",
//             fontSize: 14,
//             fontFamily: "Alexandria",
//             fontWeight: "400",
//             wordWrap: "break-word",
//           }}
//         >
//           Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
//         </p>
//       </div> */}
//     </div>
//   );
// };

// export default DragFiles;
