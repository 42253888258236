import React, { useContext } from "react";
import { Table } from "antd";
import "./style.css";
import { LanguageContext } from "../../context/LanguageContext";

const onChange = (pagination, filters, sorter, extra) => {};

const CustomTable = ({
  isSkelton,
  columns,
  data,
  onRow,
  selectedRowKey,
  pagination,
  direction,
}) => {
  const { locale, setLocale } = useContext(LanguageContext);
  const styles = {
    selectedRow: {
      backgroundColor: "#daf4e1",
    },
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        style={{ direction: locale === "en" ? "ltr" : "rtl" }}
        pagination={
          pagination
            ? {
                ...pagination,
                className: locale === "en" ? "" : "rtl-pagination", // Apply class for RTL
              }
            : false
        } // No pagination if pagination prop is false}
        // onChange={onChange}
        // pagination={pagination}
        className="DashboardTable"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onRow) {
                onRow(record);
              }
            },
            style: record?.key === selectedRowKey ? styles.selectedRow : null,
          };
        }}
      />
      <style>
        {`
        .rtl-pagination .ant-pagination-prev .ant-pagination-item-link {
  transform: rotate(180deg);
}

.rtl-pagination .ant-pagination-next .ant-pagination-item-link {
  transform: rotate(180deg);
}`}
      </style>
    </div>
  );
};
CustomTable.defaultProps = {
  onRow: null,
  selectedRowKey: null,
  pagination: true,
};

export default CustomTable;
