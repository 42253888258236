import React, { useState } from "react";
import "./style.css";

import { Loading } from "../../pages/Loading";
import AttentionIcon from "../../assets/svg/AttentionIcon";
import { Button } from "../Button";
import axios from "axios";
import Swal from "sweetalert2";

const CustomModal = ({
  isOpen,
  onClose,
  children,
  setIsLoading,
  data,
  RefetchRequestList,
  setRefetchData,
  EmployeesNote,
  selectedFile,
  fetchRequestData,
  extractedPlants,
  setUpdatedData,
  selectedPage,
  setHeadphoneRefetch,
  setListStatus
}) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");

  const handleSendClick = async () => {
    if (data) {
      setIsLoading(true);
      // let body = {
      // const formData = new FormData();
      // formData.append("request_id", data?.number);
      // formData.append("employee_id", user?.id);
      // formData.append("status", "APPROVED");
      // formData.append("employee_notes", EmployeesNote);
      // selectedFile?.length > 0 &&
      //   formData.append("filename", selectedFile[0]?.name);
      // selectedFile?.length > 0 && formData.append("filetype", "pdf");
      // selectedFile?.length > 0 &&
      //   formData.append("employee_attachment", selectedFile[0]);
      // // };
      // extractedPlants?.length > 0 &&
      //   extractedPlants?.forEach((plant, index) => {
      //     formData.append(`plants[id]`, plant.id);
      //     formData.append(`plants[plant_id]`, plant.plant_id);
      //     formData.append(`plants[approved_qty]`, plant.approved_qty);
      //   });

      // formData.forEach((value, key) => {
      //   console.log(key, value);
      // });
      const formData = new FormData();
      formData.append("request_id", data?.number);
      formData.append("employee_id", user?.id);
      formData.append("status", "APPROVED");
      // formData.append("employee_notes", EmployeesNote);

      // if (selectedFile?.length > 0) {
      //   formData.append("filename", selectedFile[0]?.name);
      //   formData.append("filetype", "pdf");
      //   formData.append("employee_attachment", selectedFile[0]);
      // }

      if (extractedPlants?.length > 0) {
        // Convert the array of plants to a JSON string and append it
        const plantsArray = extractedPlants?.map((plant) => ({
          id: plant.id,
          plant_id: plant.plant_id,
          approved_qty: plant.approved_qty,
        }));

        formData.append("plants", JSON.stringify(plantsArray));
      }

      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/ServiceRequests/updateApproval",
          formData
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(true);
          RefetchRequestList();
          fetchRequestData();
          setIsLoading(false);
          setUpdatedData("");
          onClose();
          // alert("APPROVED");
          setRefetchData((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "success",
            title: "APPROVED",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          onClose();
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };
  const handleHeadphoneSendClick = async () => {
    if (data && user && token) {
      setIsLoading(true);
      const body = {
        employee_id: user?.id,
        request_id: data?.number,
        department_id: user?.department_id,
        status: "APPROVED" /******** APPROVED OR REJECT */,
        token: token,
      };

      axios
        .post(
          "https://appadmin.buyinpk.com/portalapis/SuggestionComplaints/updateRequestStatus",
          body
        )
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          setUpdatedData("");
          onClose();
          // alert("APPROVED");
          setHeadphoneRefetch((prev) => !prev);
          const SelectedCard = sessionStorage.getItem("SelectedCard");
          setListStatus(SelectedCard);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "success",
            title: "APPROVED",
          });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          onClose();
          // alert("something went wrong");
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "something went wrong",
          });
        });
    }
  };

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div
        className=""
        style={{
          backgroundColor: "white",
          width: "540px",
          height: "379px",
          borderRadius: "6px",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <button
            style={{
              position: "absolute",
              borderStyle: "none",
              background: "transparent",
            }}
            className="modalCloseBtn"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13 1L1 13M13 13L1 1.00001"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        <div style={{ textAlign: "center" }}>
          <AttentionIcon width={96} height={96} />
          <p
            style={{
              color: "#E0BE08",
              fontSize: "24px",
              fontFamily: "Alexandria",
              fontWeight: "700",
              lineHeight: "36px",
              wordWrap: "break-word",
              textAlign: "center",
            }}
          >
            Attention !
          </p>
        </div>
        <p
          style={{
            color: "black",
            fontSize: "14px",
            fontFamily: "Alexandria",
            fontWeight: "400",
            lineHeight: "21px",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          Are you sure you want to send this request to approve? Keep in mind
          that once sent, you won't be able to make any further edits or undo
          the action.
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="modal-send-btn"
            title={"Send"}
            // onClick={() => <Loading />}
            onClick={
              selectedPage == "headphone"
                ? handleHeadphoneSendClick
                : handleSendClick
            }
          />
          <Button
            className="modal-cancel-btn"
            title={"Cancel"}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
