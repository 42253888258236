import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./style.css";
import { useContext, useState } from "react";
import { Button } from "../Button";
import axios from "axios";
import Swal from "sweetalert2";
import { LanguageContext } from "../../context/LanguageContext";

const LoginCard = ({ setIsLoading }) => {
  const navigate = useNavigate();
  const { locale, setLocale } = useContext(LanguageContext);

  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  const handleLoginButtonClick = async () => {
    setIsLoading(true);
    if (loginDetails?.username !== "" && loginDetails?.password !== "") {
      let data = {
        username: loginDetails?.username,
        password: loginDetails?.password,
      };
      axios
        .post("https://appadmin.buyinpk.com/portalapis/Employees/login", data)
        .then((res) => {
          // console.log(res?.data);
          setIsLoading(false);
          if (res?.data?.status == true) {
            sessionStorage.setItem("user", JSON.stringify(res?.data?.user));
            sessionStorage.setItem("token", res?.data?.token);
            navigate("/employee");
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              // icon: "success",
              title: res?.data?.msg,
            });
            // alert(res?.data?.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        // icon: "success",
        title: "Please fill in both username and password fields",
      });
      // alert("Please fill in both username and password fields.");
    }
  };
  return (
    <>
      <div style={{ direction: locale === "en" ? "ltr" : "rtl" }}>
        <div className="login-card-inner-div">
          <p className="welcome_message">
            {/* Welcome back */}
            <FormattedMessage id="welcome_back" />
          </p>
          <p className="login_message">
            <FormattedMessage id="login" />
          </p>
          <p className="username-p">
            <FormattedMessage id="user_name" />
          </p>
          <input
            type="text"
            className="username-input"
            onChange={(e) => {
              setLoginDetails((prev) => ({
                ...prev,
                username: e.target.value,
              }));
            }}
          />
          <p className="password-p">
            <FormattedMessage id="password" />
          </p>
          <input
            type="password"
            className="username-input width-auto"
            onChange={(e) => {
              setLoginDetails((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
          />
          <div className="remember-me-div">
            <div className="remember-me-inner-div">
              <input type="checkbox" className="remember-me-input" />
              <p className="remember-me-p">
                <FormattedMessage id="remember_me" />
              </p>
            </div>
            <Button
              title={<FormattedMessage id="login" />}
              className={"login-btn"}
              onClick={handleLoginButtonClick}
            />
          </div>{" "}
          <p className="reset-password-p">
            <FormattedMessage id="reset_password" />
            <a>
              {" "}
              <span className="reset-password-span">
                <FormattedMessage id="reset_password" />
              </span>
            </a>{" "}
            <FormattedMessage id="or_call" />{" "}
            <a href="">
              <span className="helpdesk-span">
                <FormattedMessage id="helpdesk" />
              </span>
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginCard;
